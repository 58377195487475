import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { DataTable } from '@andresouzaabreu/vue-data-table';
import '@andresouzaabreu/vue-data-table/dist/DataTable.css';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import './styles/main.scss';
import { calculatePercentageNumberFormat, dateFormat, handleSyncRequestLoader, roundNumberFormat } from '@/Util/auth';
import store from './store';
import _ from 'lodash';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import CrudTable from "@/components/CrudTable";
import { i18n } from './Localization';
import BootstrapVue from 'bootstrap-vue';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import NotifyMixin from "@/Util/NotifyMixin";
import Vuelidate from 'vuelidate';
import VueSocialauth from 'vue-social-auth';
import * as msal from '@azure/msal-browser';
const AuthOption = {
    providers: {
        google: {
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URL,
            // prompt: 'consent',
        }
    },
};
const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${ process.env.VUE_APP_AZURE_TENANT_ID }/`,
        redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};
const msalInstance = new msal.PublicClientApplication(msalConfig);
async function initializeMsal() {
    await msalInstance.initialize();
}

Vue.prototype.$msal = msalInstance;
Vue.use(VueSocialauth, AuthOption);
Vue.use(VueAxios, axios);
Vue.component('data-table', DataTable);
Vue.component('datetime', Datetime);
Vue.component('crud-table', CrudTable);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

Vue.use(VueToast, {
    position: 'top-right'
});

Vue.use(BootstrapVue);
Vue.use(BootstrapIconsPlugin);
Vue.use(Vuelidate);

Vue.prototype._ = _;
Vue.prototype.$global = {
    dateFormat: (date, format = 'DD/MM/YYYY') => ( ( date ) ? dateFormat(date, format) : null ),
    dateTimeFormat: (date, format = 'DD/MM/YYYY HH:mm:ss') => ( ( date ) ? dateFormat(date, format) : null ),
    calculatePercentageNumberFormat: (number) => calculatePercentageNumberFormat(number),
    roundNumberFormat: (number, digit) => roundNumberFormat(number, digit),
};
Vue.mixin(NotifyMixin);
initializeMsal().then(() => {
    new Vue({
        store,
        router,
        i18n,
        render: h => h(App),
        created() {
            handleSyncRequestLoader(store, process.env.VUE_APP_API_URL);
        },
    }).$mount('#app');    
}).catch(error => {
    console.error('Failed to initialize MSAL:', error);
});