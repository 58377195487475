<template>
    <div :class="{'mt-5' : showReport == 'block'}" id="content-to-export" :style="{display : showReport}">
        <div class="generate-report-page w-100">
            <div class="container">
                <div class="row">
                    <div class="col-auto d-flex align-items-center">
                        <a :href="pageUrl">
                            <img :src="base64Logo" alt="logo" width="150" />
                        </a>
                    </div>
                </div>
                <div class="row d-flex align-items-center">
                    <div class="col-auto"><h4 class="fw-bold">{{ $t('title.report') }}</h4></div>
                </div>
                <div class="row d-flex align-items-center mb-36">
                    <div class="info-report">
                        <table style="border: 0px solid transparent;  font-size: 16px;">
                            <tbody>
                                <tr style="border: 0px solid transparent;">
                                    <td style="border: 0px solid transparent;padding-right: 14px;">
                                        <span class="title fw-bold text-black"
                                              style="margin-right:5px">ID number:</span>
                                        <span class="value sec-color">{{ data?.id_number }}</span>
                                    </td>
                                    <td style="border: 0px solid transparent;padding-right: 14px;">
                                        <span class="title fw-bold text-black" style="margin-right:5px">Gender:</span>
                                        <span class="value sec-color">{{ _.capitalize(data?.gender) }}</span>
                                    </td>
                                    <td style="border: 0px solid transparent;padding-right: 14px;">
                                        <span class="title fw-bold text-black" style="margin-right:5px">Birthday:</span>
                                        <span class="value sec-color">{{ $global.dateFormat(data?.dob) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row report-block m-0 border-0 p-0">
                    <div class="main-block p-0">
                        <div class="block">
                            <h5>Summary</h5>
                            <div class="summary-report export-report">
                                <div class="report-preview" v-html="report" />
                                <div class="mt-2 text-center footer-link">
                                    <p class="fw-bold">Generated by <a :href="pageUrl">inklyze.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import { mapGetters } from "vuex";
import { request } from "@/Util/Request";

export default {
    data() {
        return {
            showReport: 'none',
            base64Logo: '',
            cssContent: ''
        };
    },
    props: {
        'report': {
            required: true
        },
        'data': {
            required: true
        },
    },
    computed: {
        ...mapGetters(['loader']),
        pageUrl() {
            return window.location.origin;
        }
    },
    mounted() {
        this.loadSvgAsBase64();
        this.loadCss();
    },
    methods: {
        async loadSvgAsBase64() {
            try {
                // Fetch the SVG file from your assets directory or server
                const response = await axios.get(require('@/assets/logo.svg'), {
                    responseType: 'text', // Ensure the response is treated as text
                });

                // Convert SVG content to Base64
                const svgContent = response.data;
                const encodedData = btoa(unescape(encodeURIComponent(svgContent)));
                this.base64Logo = `data:image/svg+xml;base64,${ encodedData }`;
            } catch (error) {
                console.error('Error loading SVG:', error);
            }
        },
        async loadCss() {
            try {
                const response = await axios.get('/css/report-pdf.css', {
                    responseType: 'text', // Ensure the response is treated as text
                });

                // Get the CSS content
                this.cssContent = `<style>${ response.data }</style>`;
            } catch (error) {
                console.error('Error loading SVG:', error);
            }
        },
        async exportToPDF() {
            this.$emit('loader', true);
            try {
                this.$store.dispatch("loader", true);
                const exportContent = document.getElementById('content-to-export').innerHTML;
                await request({
                    method: 'post',
                    url: `/tests/export/pdf`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob', // Set the response type to blob
                    data: {
                        html: this.cssContent + exportContent, // Send the HTML as a JSON payload
                    },
                }).then((response) => {
                    // Handle the Blob response
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${ this.data.id_number }_report.pdf`; // Set the download filename
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url); // Clean up the URL object
                    this.$store.dispatch("loader", false);
                    this.$emit('loader', false);
                })
                .catch((error) => {
                    console.error('Error downloading PDF:', error);
                    this.$store.dispatch("loader", false);
                    this.$emit('loader', false);
                });
            } catch (error) {
                this.$emit('loader', false);
                this.$store.dispatch("loader", false);
                console.error('Error exporting to PDF:', error);
            }
        },
    }
};
</script>

