<template>
    <div class="pt-5 container p-0">
        <b-row>
            <b-col sm="12" xs="12" md="8">
                <form autocomplete="off">
                    <b-card>
                        <b-card-body>
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3 mb-md-0 mb-lg-0">
                                    <!--  add class was-validated to the previous line for invalid design -->
                                    <label for="name" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.fullName')
                                        }}</label>
                                    <div class="d-flex">
                                        <b-form-input
                                            :value="user.name"
                                            type="text"
                                            class="form-control form-input mr-20"
                                            id="name"
                                            name="name"
                                            disabled="disabled"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <!--  add class was-validated to the previous line for invalid design -->
                                    <label for="name" class="fw-medium mb-2 text-grey-input">{{
                                            $t('title.email')
                                        }}</label>
                                    <div class="d-flex">
                                        <b-form-input
                                            :value="user.email"
                                            type="text"
                                            class="form-control form-input mr-20"
                                            id="email"
                                            name="email"
                                            disabled="disabled"
                                        />
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </form>
            </b-col>
        </b-row>
        <change-password/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangePassword from "@/components/ChangePassword"


export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    components : {
        ChangePassword
    }
};
</script>
