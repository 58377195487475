<template>
    <div>
        <div class="generate-report-page w-100 pt-3">
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="col-auto"><h4 class="fw-bold">{{ $t('title.report') }}</h4></div>
                </div>
                <div class="row d-flex align-items-center mb-36 mobile-responsive-report">
                    <div class="col-auto back-button-width ps-0-mobile">
                        <router-link :to="{'name':'adminTestForm',params:{id:$route.params.id,operation:'detail'}}" class="sec-color text-decoration-none"
                                     :disabled="loader"
                        >
                            <svg
                                class="me-2"
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                                />
                            </svg>
                            {{ $t('title.back') }}
                        </router-link>
                    </div>
                    <div class="info-report d-flex flex-wrap gap-32 col-auto ps-0">
                        <div class="id d-flex gap-2">
                            <div class="title fw-bold text-black">ID number:</div>
                            <div class="value sec-color">{{ detail?.id_number }}</div>
                        </div>
                        <div class="gender d-flex gap-2">
                            <div class="title fw-bold text-black">Gender:</div>
                            <div class="value sec-color">{{ _.capitalize(detail?.gender) }}</div>
                        </div>
                        <div class="gender d-flex gap-2">
                            <div class="title fw-bold text-black">Birthday:</div>
                            <div class="value sec-color">{{ $global.dateFormat(detail?.dob) }}</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-auto ms-auto d-flex gap-1 gap-md-2 flex-wrap mt-4 mt-md-0">
                        <button @click="handleExportToPDF" class="btn main-btn" :disabled="exportLoader">
                            {{ $t('title.exportToPDF') }}
                        </button>
                    </div>
                </div>
                <div class="row report-block m-0">
                    <div class="main-block">
                        <div class="block">
                            <div class="text-center" v-if="loader">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                            <h5 class="mt-4">Summary</h5>
                            <div class="summary-report"
                                 v-if="response">
                                <div class="report-preview overflow-x-auto"
                                     v-html="(response.choices && response.choices[0]['message']['content']) ?? response" />
                            </div>
                        </div>
                        <div class="text-center m-auto" v-if="!response">
                            <p class="fw-bold fs-20 text-success mt-20">{{ $t('title.reportProcessMessage') }}</p>
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <exportReport ref="exportReport" :data="detail" v-on:loader="viewLoader"
                      :report="(response.choices && response.choices[0]['message']['content']) ?? response"
                      v-if="response" />
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import { mapGetters } from "vuex";
import exportReport from "@/views/admin/test/exportReport";

export default {
    data() {
        return {
            response: null,
            questions: [],
            detail: null,
            isReportGenerate: false,
            exportLoader: false
        };
    },
    components: {
        exportReport
    },
    mounted() {
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);
        }
    },
    methods: {
        handleExportToPDF() {
            this.$refs['exportReport'].exportToPDF();
        },
        viewLoader(loader = false) {
            this.exportLoader = loader;
        },
        async getDetail(id) {
            try {
                this.isReportGenerate = true;
                const response = await request({
                    method: 'post',
                    url: `admin/tests/generate/report/${ id }`,
                    data: {
                        'url': `${ window.location.protocol }//${ window.location.hostname }${ window.location.port ? `:${ window.location.port }` : '' }`
                    }
                });

                const { data } = response;
                const { summary, questions, test, error } = data;

                this.response = summary;
                this.questions = questions;
                this.detail = test;
                this.isReportGenerate = false;
                if ( error.length != 0 ) {
                    if ( error && error.code == 429 ) {
                        this.notifyErrorWithMsg("Temporary having some issue to generate report. Please try after later.");
                    } else {
                        this.notifyError();
                    }
                }
            } catch (error) {
                this.isReportGenerate = false;
                if ( error.request && error.request.status && error.request.status !== 401 ) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>

<style lang="scss">
.summary-report {
    white-space: pre-line;
    overflow-x: auto;
}

.generate-report-page {
    .report-block {
        position: relative;
    }

    .report-block .loader-message {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 28px;
    }

    .report-block .fa.fa-spinner.fa-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
    }

    @media(max-width: 767.98px) {
        .report-block .main-block {
            overflow-y: auto;
            height: calc(100vh - 400px);
            overflow-x: hidden;
        }
        .report-block .loader-message {
            top: 53px;
            transform: translate(-50%, 0%);
            width: 100%;
            text-align: center;
            font-size: 19px !important;
        }
        .report-block .fa.fa-spinner.fa-spin {
            top: 70% !important;
        }
    }

    @media(max-width: 1024px) {
        .report-block .loader-message {
            font-size: 19px !important;
        }
    }

    .report-preview {
        display: block;
        width: fit-content;
    }
}
</style>

<style scoped>
@media screen and (max-width: 768px) {
    .mobile-responsive-report {
        margin-bottom: 20px !important;
        margin: 0px;
    }
}
</style>
