<template>
    <div id="app">
        <Localization />
    </div>
</template>

<script>

import Localization from "@/Localization";
import {  hasAuthUser } from "@/Util/auth";
import { mapGetters } from "vuex";

export default {
    name: 'App',
    components: {
        Localization
    },
    mounted() {
        if (!hasAuthUser() && !_.isEmpty(this.user) ) {
            this.$store.dispatch("user", null);
        }
    },
    computed: {
        ...mapGetters(['user', 'getUnAuthorized']),
        unAuthorizedValue() {
            return this.getUnAuthorized;
        }
    }
};
</script>
