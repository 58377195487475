<template>
    <div>
        <b-modal v-model="isOpenModal" id="changeEmailVerifyOTPModal"  :hide-header="true"
                 :hide-footer="true">
            <form action="" @submit.prevent="verifyNewEmailOTP">
                <h3 class="mb-3">{{ $t('title.verifyYourEmail') }}</h3>
                <p class="fs-18 mb-3 grey-info">
                    {{ $t('title.weSentVerificationCode') }}  {{email}}
                </p>
                <div
                    class="input-group mb-3 d-flex justify-content-center gap-3 otp-input"
                >
                    <!-- is-invalid or was-validated -->
                    <input
                        v-for="(digitInput, index) in otpLength"
                        :key="index"
                        ref="otpInput"
                        v-model="otpArray[index]"
                        @keydown="handleEnter(index, $event)"
                        @input="handleInput(index, $event)"
                        @paste="handlePaste(index, $event)"
                        type="text"
                        step="1"
                        maxlength="1"
                        :class="[`form-control`, `default-input`, `text-code`, formErrors.first('otp')?`is-invalid`:(isValidateOTP?`is-valid`:``)]"
                        @focus="removeError('otp')" />

                    <div class="invalid-feedback" v-if="formErrors.get('otp')">
                                        <span v-for="(error, index) in formErrors.get('otp')" :key="index">{{
                                                error
                                            }}</span>
                    </div>
                </div>
                <p class="text-ship-grey text-left mb-3">
                    {{ $t('title.didNotGetCode') }}
                    <a
                        href="javascript:void(0);"
                        class="text-main text-decoration-none"
                        v-on:click="resendVerificationOTP"
                    >{{ $t('title.sendAgain') }}</a
                    >
                </p>

                <button type="submit" class="btn main-btn d-block fs-20 w-100 py-3 btnNextTab" :disabled="loading">
                    <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                    {{ $t('title.submit') }}
                </button>
            </form>
        </b-modal>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { request } from "@/Util/Request";
import {mapGetters} from "vuex";
import {removeStorage} from "@/Util/auth";

const DEFAULT_FORM_STATE = {
    otp:null,
};

export default {
    props: {
        otpLength: {
            type: Number,
            default: 4
        },
        email : {
            type: String
        }
    },
    data() {
        return {
            formFields: { ...DEFAULT_FORM_STATE },
            isOpenModal: false,
            formErrors: new Error({}),
            isDisabledSaveBtn: false,
            otpArray: [],
            otpCode: null,
            isValidateOTP: false,
            loading: false
        };
    },
    methods: {
        handleToggleModal() {
            this.isOpenModal = !this.isOpenModal;
            this.otpArray = [];
            this.formFields.otp = null;
            this.isValidateOTP = false;
            this.formErrors = new Error({});
        },
        async validatedOTP(){
            try {
                this.loading    =   true;
                let payload     =   this.formFields;
                const response  =   await request({
                    method:'POST',
                    url:`/profile/check-otp`,
                    data: payload
                });
                const { data } = response;
                if(data && data.isValidateOTP) {
                    this.isValidateOTP = data.isValidateOTP;
                    if(this.formErrors.$errors.otp && this.isValidateOTP) {
                        delete this.formErrors.$errors.otp;
                    }
                }
            } catch (error) {
                this.loading    =   false;
                this.isValidateOTP = false;
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if (error.request && error.request.status && error.request.status === 429) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                    return false;
                }
            }
            this.loading = false;
        },
        async verifyNewEmailOTP(){
            try {
                this.loading    =   true;
                let payload     =   this.formFields;
                const response  =   await request({
                    method:'POST',
                    url:`/profile/verify-otp`,
                    data: payload
                });
                const { data } = response;
                if (data) {
                    this.handleToggleModal();
                    this.notifySuccessWithMsg(this.$t('title.emailChangedSuccessMsg'));
                    await this.$store.dispatch("user", data);
                    removeStorage('auth');
                    await this.$store.dispatch('user', null);
                    await this.$router.replace({ name: 'login' });
                }
            } catch (error) {
                this.loading    =   false;
                this.isValidateOTP = false;
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }

                if (error.request && error.request.status && error.request.status === 429) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                    return false;
                }
            }
            this.loading = false;
        },
        handleInput(index, event) {
            if (index < this.otpLength - 1) {
                this.$refs.otpInput[index + 1].focus();
            }
            if (index === this.otpLength - 1) {
                this.$refs.otpInput[index].blur();
            }

            let joinedValue = this.otpArray.join("");
            if (joinedValue.length === this.otpLength) {
                this.formFields.otp = joinedValue;
                if(/^[0-9]*$/.test(this.formFields.otp)) {
                    this.validatedOTP();
                }
            }
        },
        handlePasteEvent(index){
            navigator.clipboard.readText().then((pastedText) => {
                if(/^\d+$/.test(pastedText)) {
                    const shavedText = pastedText.replace(/[^0-9]/g, '')
                    for (let i = 0; i < shavedText.length && index + i < this.otpLength; i++) {
                        this.otpArray[index + i] = shavedText.charAt(i);
                        if (index + i + 1 < this.otpLength) {
                            this.$refs.otpInput[index + i + 1].dispatchEvent(
                                new Event("input")
                            );
                        }
                    }
                    let joinedValue = this.otpArray.join("");
                    if (joinedValue.length === this.otpLength) {
                        this.formFields.otp = joinedValue;
                        if(/^[0-9]*$/.test(this.formFields.otp)) {
                            this.validatedOTP();
                        }
                    }
                } else {
                    this.otpArray = [];
                    this.$refs.otpInput[0].focus();
                }
            });
        },
        handleEnter(index, event) {
            const keypressed = event.keyCode || event.which;

            if ( (( (keypressed < 48 || keypressed > 57) && (keypressed < 96 || keypressed > 105))) && keypressed !== 8 && !event.ctrlKey) {
                event.preventDefault();
            }
            if ( keypressed === 8 || keypressed === 46 ) {
                event.preventDefault();
                if ( !this.otpArray[index] && index > 0 ) {
                    this.otpArray[index - 1] = "";
                    this.$refs.otpInput[index - 1].value = "";
                    this.$refs.otpInput[Math.max(0, index - 1)].focus();
                } else {
                    this.otpArray[index] = "";
                    this.$refs.otpInput[index].value = "";
                }
                this.otpCode = null;
                this.isValidateOTP = false;
            }
        },
        handlePaste(index, event) {
            this.handlePasteEvent(index);
            this.$emit("paste", event);
        },
        async removeError(key) {
            this.formErrors.remove(key);
        },
        async resendVerificationOTP(){
            try{
                this.loading    =   true;
                const response  =   await request({
                    method:'POST',
                    url:`/profile/resend-otp`,
                });
                const { data } = response;
                if (data) {
                    this.notifySuccessWithMsg("OTP Re-Sent Successfully!");
                }
            }catch(error){
                if(error.request && error.request.status && error.request.status===422){
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                }
                if (error.request && error.request.status && error.request.status === 429) {
                    this.formErrors = new Error({
                        email: 'unauthentic'
                    });
                }
            }
            this.loading = false;
        }
    }
};
</script>
