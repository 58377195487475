<template>
    <div>
        <div class="card d-none d-md-block mt-5">
            <div class="card-header">
                <h5>
                    {{ $t('title.users') }}
                </h5>
            </div>
            <div class="card-body">
                <crud-table :columns="columns" :list-url="listUrl" ref="user-table">
                    <template v-slot:default>
                        <router-link :to="{'name':'adminUserForm',params:{operation:'create'}}" :disable="loader"
                                     class="btn main-btn gap-1 me-2" title="Add New">
                                <span class="d-flex justify-content-center align-items-center gap-1">
                                    {{ $t('title.addNewUser') }} <i class="bi bi-plus-lg"></i>
                               </span>
                        </router-link>
                    </template>
                    <template #cell(purchased_plan_start_date)="{item}">
                        {{ $global.dateFormat(item.purchased_plan_start_date) }}
                    </template>
                    <template #cell(purchased_plan_renew_date)="{item}">
                        {{ $global.dateFormat(item.purchased_plan_renew_date) }}
                    </template>
                    <template #cell(register_date)="{item}">
                        {{ $global.dateFormat(item.register_date) }}
                    </template>
                    <template #cell(last_sign_in_date)="{item}">
                        {{ $global.dateFormat(item.last_sign_in_date) }}
                    </template>
                    <template #cell(is_active)="{item}">
                        <span v-if="item.is_active" :class="`badge bg-success text-white`"> {{ $t('title.yes') }}</span>
                        <span v-else :class="`badge bg-danger text-white`"> {{ $t('title.no') }}</span>
                    </template>
                    <template #cell(is_new)="{item}">
                        <span v-if="item.is_new" :class="`badge bg-danger text-white`"> {{ $t('title.no') }}</span>
                        <span v-else :class="`badge bg-success text-white`"> {{ $t('title.yes') }}</span>
                    </template>
                    <template #cell(action)="{item}">
                        <router-link :to="{'name':'adminUserForm',params:{id:item.id,operation:'edit'}}"
                                     @click="operation = 'edit'" v-if="user?.email !== item.email">
                            <i class="bi bi-pencil-fill" />
                        </router-link>
                        <a href="javascript:;" @click.prevent="handleDelete(item.id)" class="ms-2"
                           v-if="user?.email !== item.email">
                            <i class="bi bi-trash-fill"></i>
                        </a>
                    </template>
                </crud-table>
            </div>
        </div>
        <div class="d-block d-md-none mt-5">
            <div class="card-header b-none d-flex gap-2 mb-3">
                <h5 class="col">
                    {{ $t('title.users') }}
                </h5>
                <router-link :to="{'name':'adminUserForm',params:{operation:'create'}}" :disable="loader"
                             class="btn main-btn col" title="Add New">
                            <span class="d-flex justify-content-center align-items-center gap-1">
                                {{ $t('title.addNewUser') }} <i class="bi bi-plus-lg"></i>
                           </span>
                </router-link>
            </div>
            <user-card-table :columns="columns" :list-url="listUrl"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserCardTable from "./partials/userCardTable";

export default {
    components: {
        UserCardTable
    },
    data() {
        return {
            operation: null,
            listUrl: '/admin/users',
            columns: [
                {
                    key: 'email',
                    label: this.$t('title.email'),
                    sortable: true
                },
                {
                    key: 'credit',
                    label: this.$t('title.credit'),
                    sortable: true
                },
                {
                    key: 'purchased_plan',
                    label: this.$t('title.purchasedPlan'),
                    sortable: true
                },
                {
                    key: 'plan_status',
                    label: this.$t('title.planStatus'),
                    sortable: true
                },
                {
                    key: 'purchased_plan_start_date',
                    label: this.$t('title.purchasedPlanStartDate'),
                    sortable: true
                },
                {
                    key: 'purchased_plan_renew_date',
                    label: this.$t('title.purchasedPlanRenewDate'),
                    sortable: true
                },
                {
                    key: 'is_active',
                    label: this.$t('title.isActive'),
                    sortable: false
                },
                {
                    key: 'is_new',
                    label: this.$t('title.isVerified'),
                    sortable: true
                },
                {
                    key: 'register_date',
                    label: this.$t('title.registerDate'),
                    sortable: true
                },
                {
                    key: 'last_sign_in_date',
                    label: this.$t('title.lastLoginDate'),
                    sortable: true
                },
                {
                    key: 'action',
                    label: this.$t('title.action'),
                    sortable: false
                },
            ],
            addTestModal: false,
            users: []
        };
    },
    methods: {
        async handleDelete(id) {
            this.$refs['user-table'].handleDeleteShow(`/admin/users/${ id }`, 'User');
        },
        refreshList() {
            this.$refs['user-table'].refreshTableData();
        },
    },
    computed: {
        ...mapGetters(['loader', 'user'])
    }
};
</script>
