<template>
    <div>
        <div v-for="(report, index) in tableData" :key="index" class="card-el p-3 mb-3 report-card col-12">
            <div class="card-header d-flex justify-content-between">
                <div class="d-flex align-items-center fw-bold">{{ $t('title.idNumber') }}:</div>
                <span class="d-flex flex-column align-items-end justify-content-space-between">
                  <span class="price fw-bold">{{ report.id_number }}</span>
                </span>
            </div>
            <hr />
            <div class="card-body">
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder col-6">{{ $t('title.numberOfQuestions') }}:</span>
                    <span class="result text-end col-6">{{ report.total_question }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.gender') }}:</span>
                    <span class="result w-75 text-end">{{ _.capitalize(report.gender) }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.birthday') }}:</span>
                    <span class="result text-end col-6">{{ $global.dateFormat(report.dob) }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center" v-if="tableName == 'complete'">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.dateCompleted') }}:</span>
                    <span class="result text-end col-6">{{ $global.dateFormat(report.completed_at) }}</span>
                </p>
            </div>
            <hr />
            <div class="d-flex justify-content-end gap-2 action">
                <test-action :data="report"/>
            </div>
        </div>
    </div>
</template>

<script>
import TestAction from "@/views/user/test/partials/testAction";
export default {
    name: 'Card',
    components: {TestAction},
    props: {
        tableData: {
            type: Array,
            required: true
        },
        tableName: {
            tableName: String,
            required: false
        }
    },
}
</script>
