<template>
    <div>
        <div class="card d-none d-md-block mt-5">
            <div class="card-header">
                <h5>
                    {{ $t('title.tests') }}
                </h5>
            </div>
            <div class="card-body">
                <crud-table :columns="columns" :list-url="listUrl" ref="test-table">
                    <template #cell(dob)="{item}">
                        {{ $global.dateFormat(item.dob) }}
                    </template>
                    <template #cell(completed_at)="{item}">
                        {{ $global.dateFormat(item.completed_at) }}
                    </template>
                    <template #cell(start_date)="{item}">
                        {{ $global.dateFormat(item.start_date) }}
                    </template>
                    <template #cell(gender)="{item}">
                        {{ _.capitalize(item.gender) }}
                    </template>
                    <template #cell(action)="{item}">
                        <router-link :to="{'name':'adminTestForm',params:{id:item.id,operation:'detail'}}"
                                     @click="operation = 'detail'" class="ms-2">
                            <i class="bi bi-eye-fill" />
                        </router-link>
                        <a href="javascript:;" @click.prevent="handleDelete(item.id)" class="ms-2">
                            <i class="bi bi-trash-fill"></i>
                        </a>
                    </template>
                    <template #cell(is_report_generated)="{item}">
                        <span v-if="!item.is_report_generated" :class="`badge bg-danger text-white`"> {{ $t('title.no') }}</span>
                        <span v-else :class="`badge bg-success text-white`"> {{ $t('title.yes') }}</span>
                    </template>
                </crud-table>
            </div>
        </div>
        <div class="d-block d-md-none mt-5">
            <div class="card-header b-none mb-3">
                <h5>
                    {{ $t('title.tests') }}
                </h5>
            </div>
            <test-card-table :columns="columns" :list-url="listUrl"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TestCardTable from './partials/testCardTable'

export default {
    components: {
      TestCardTable
    },
    data() {
        return {
            operation: null,
            listUrl: '/admin/tests',
            columns: [
                {
                    key: 'id_number',
                    label: this.$t('title.idNumber'),
                    sortable: false
                },
                {
                    key: 'creator',
                    label: this.$t('title.creator'),
                    sortable: false
                },
                {
                    key: 'total_question',
                    label: this.$t('title.numberOfQuestions'),
                    sortable: true
                },
                {
                    key: 'gender',
                    label: this.$t('title.gender'),
                    sortable: true
                },
                {
                    key: 'dob',
                    label: this.$t('title.birthday'),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t('title.startDate'),
                    sortable: true
                },
                {
                    key: 'completed_at',
                    label: this.$t('title.dateCompleted'),
                    sortable: true
                },
                {
                    key: 'is_report_generated',
                    label: this.$t('title.isReportGenerated'),
                    sortable: true
                },
                {
                    key: 'action',
                    label: this.$t('title.action'),
                    sortable: false
                },
            ],
            addTestModal: false,
            tests: []
        };
    },
    methods: {
        async handleDelete(id) {
            this.$refs['test-table'].handleDeleteShow(`/admin/tests/${ id }`, 'Test');
        },
        refreshList() {
            this.$refs['test-table'].refreshTableData();
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
