<template>
    <div>
        <router-link :to="{'name':'adminTestForm',params:{id:data.id,operation:'detail'}}"
                     @click="operation = 'detail'" class="ms-2">
            <i class="bi bi-eye-fill" />
        </router-link>
        <a href="javascript:;" @click.prevent="handleDelete(data.id)" class="ms-2">
            <i class="bi bi-trash-fill"></i>
        </a>
    </div>
</template>
<script>
export default {
    name: 'testAction',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            operation: null,
        }
    },
    methods : {
        handleDelete(testId) {
            this.$emit('handle-delete-show', testId);
        }
    }
}
</script>
