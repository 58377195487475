<template>
    <div>
        <router-link :to="{'name':'adminUserForm',params:{id:data.id,operation:'edit'}}"
                     @click="operation = 'edit'" v-if="user?.email !== data.email">
            <i class="bi bi-pencil-fill" />
        </router-link>
        <a href="javascript:;" @click.prevent="handleDelete(data.id)" class="ms-2"
           v-if="user?.email !== data.email">
            <i class="bi bi-trash-fill"></i>
        </a>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: 'userAction',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            operation: null,
        }
    },
    methods : {
        handleDelete(userId) {
            this.$emit('handle-delete-show', userId);
        }
    },
    computed: {
        ...mapGetters(['user'])
    }
}
</script>
