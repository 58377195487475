<template>
    <div>
        <b-modal v-model="isOpenModal" id="leaveQuestionSaveAlertModal" :tabindex="-1" :hide-header="true"
                 :hide-footer="true">
            <p>Do you want to save questions? </p>
            <div class="modal-footer d-flex mr-2 px-0 gap-3">
                <button type="button" class="btn main-btn col m-0" @click="handleSubmitLogout">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelLogoutModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpenModal: false,
        };
    },
    methods: {
        handleToggleModal() {
            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitLogout() {
            this.isOpenModal = false;
            this.$emit('handle-submit-test-question');
        },
        async cancelLogoutModal() {
            this.isOpenModal = false;
            this.$emit('cancel-submit-test-question');
        }
    }
};
</script>
