<template>
    <div>
        <div class="row">
            <div class="col-12 text-center py-2" v-if="loader">
                <i class="fa fa-spin fa-spinner"></i>
            </div>
            <div class="col-12 mb-3">
                <div class="position-relative input-search">
                    <b-form-input :disable="loader" v-model="query.search" :placeholder="`${$t('title.enterToSearch')}`"
                                id="filter-input"
                                class="w-100 form-control default-input"
                                @keyup.enter="handleSearch" />
                    <span class="icon-inside">
                        <a :disabled="loader" @click="handleSearch" title="search">
                            <i class="fa fa-search"></i>
                        </a>
                    </span>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group mb-4">
                    <v-select
                        :disable="loader"
                        :options="pageSelection"
                        label="name"
                        class="default-select w-100"
                        id="perPage"
                        v-model="query.perPage"
                        :searchable="false"
                    />
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex gap-2 justify-content-end">
                    <!-- slot for add new button-->
                    <slot></slot>
                    <!-- slot for filter button-->
                    <slot name="filter-slot"></slot>
                    <button :disabled="loader"
                            class="btn main-btn" title="refresh" @click="refreshTableData">
                        <i class="bi bi-bootstrap-reboot"></i>
                    </button>
                </div>
            </div>
        </div>
        <div v-for="(test, index) in dataSources" :key="index" class="card-el p-3 mb-3 report-card col-12">
            <div class="card-header d-flex justify-content-between">
                <div class="d-flex align-items-center fw-bold">{{ $t('title.idNumber') }}:</div>
                <span class="d-flex flex-column align-items-end justify-content-space-between">
                  <span class="price fw-bold">{{ test.id_number }}</span>
                </span>
            </div>
            <hr />
            <div class="card-body">
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder col-6">{{ $t('title.creator') }}:</span>
                    <span class="result text-end col-6 email-hide">{{ test.creator }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder col-6">{{ $t('title.numberOfQuestions') }}:</span>
                    <span class="result text-end col-6">{{ test.total_question }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.gender') }}:</span>
                    <span class="result w-75 text-end">{{ _.capitalize(test.gender) }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.birthday') }}:</span>
                    <span class="result text-end col-6">{{ $global.dateFormat(test.dob) }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.startDate') }}:</span>
                    <span class="result text-end col-6">{{ $global.dateFormat(test.start_date) }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center mb-2">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.dateCompleted') }}:</span>
                    <span class="result text-end col-6">{{ $global.dateFormat(test.completed_at) }}</span>
                </p>
                <p class="d-flex justify-content-between align-items-center">
                    <span class="title fw-bold text-black-50 fw-bolder">{{ $t('title.isReportGenerated') }}:</span>
                    <span v-if="!test.is_report_generated" :class="`badge bg-danger text-white result text-end`"> {{ $t('title.no') }}</span>
                    <span v-else :class="`badge bg-success text-white result text-end`"> {{ $t('title.yes') }}</span>
                </p>
            </div>
            <hr />
            <div class="d-flex justify-content-end gap-2 action">
                <test-action :data="test" @handle-delete-show="handleDeleteShow"/>
            </div>
        </div>
        <div class="row d-flex align-items-center gap-3 mt-4 mx-0">
            <div class="col pe-0">
                <b-pagination
                    v-if="pagination"
                    v-model="query.page"
                    :total-rows="pagination.total"
                    :per-page="query.perPage"
                    prev-class="previous-arrow"
                    next-class="next-arrow"
                    first-number
                    last-number
                    align="right"
                    class="default-design-pagination"
                    size="sm"
                ></b-pagination>
            </div>
        </div>
        <delete-test-modal ref="deleteTestModal"  @handleRefreshList="handleRefreshList"/>
    </div>
</template>

<script>
import TestAction from "./testAction";
import DeleteTestModal from "../modal/deleteTestModal"
import ListingMixin from "@/Util/ListingMixin";
import {mapGetters} from "vuex";
export default {
    mixins: [ListingMixin],
    name: 'testCardTable',
    components: {TestAction, DeleteTestModal},
    methods: {
        handleDeleteShow(id) {
            let url = `/admin/tests/${ id }`;
            this.$refs.deleteTestModal.handleToggleModal(url);
        },
    },
    computed: {
        ...mapGetters(['loader']),
    },
}
</script>
<style scoped>

.icon-inside {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #ccc; /* Change the color as needed */
}

.icon-inside i {
    pointer-events: none;
}
</style>
