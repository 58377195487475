<template>
    <div>
            <h2>Localstorage Data</h2>
        <p>
            {{this.vuexData}}
        </p>
        <h2>Cookie Data</h2>
        <p>
            {{this.authData}}
        </p>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            vuexData: null,
            authData: null
        };
    },
    mounted() {
        // Retrieve data from localStorage when the component is mounted
        this.vuexData = (localStorage.getItem('vuex')) ?? 'not found';
        this.authData = (Cookies.get('auth')) ?? 'not found';
    },
};
</script>
